<template>
  <div class="primary-folder">
    <template v-if="loading">
      <div class="primary-folder-header">
        <div class="primary-folder-header-tit">
          <span class="pos"></span>
          <p>{{ book.bookName }}</p>
          <!-- style="white-space: pre-wrap" -->
        </div>
        <div class="primary-folder-header-info">
          <span class="pos"></span>
          <div class="info">
            <span
              v-if="book.publisher"
              class="publisher"
              v-html="book.publisher"
            ></span>
            <div class="small-box">
              <span v-if="book.studyCount" class="count"
                >{{ book.studyCount }}人学习</span
              >
              <label
                v-if="book.allResUrl != ''"
                class="all"
                @click="tapPopup(book.bookName, book.allResUrl)"
                >整书资源下载</label
              >
              <!-- <router-link
                :to="{
                  name: 'propose',
                  query: { bid: bid, fid: fid, source: 2 },
                }"
                class="opinion"
              >
                <span class="icon"
                  ><img src="@/assets/images/op.png" alt=""
                /></span>
                <em>意见反馈</em>
              </router-link> -->
            </div>
          </div>
        </div>
        <div class="primary-folder-header-img">
          <img :src="book.coverImg ? book.coverImg : cover" alt="" />
        </div>
      </div>
      <div v-if="book.slogan" class="primary-explain">
        <p v-html="book.slogan"></p>
      </div>
      <!-- <div v-else class="primary-explain">
        <p>部分课件已上传，可下载使用！</p>
        <p>完整课件正在制作中，敬请期待！</p>
      </div> -->
      <div class="primary-folder-list">
        <div class="primary-folder-list-tit">
          <span>单元课件下载</span>
        </div>
        <div class="primary-folder-list-data">
          <template v-if="resListState">
            <div
              v-for="item in resList"
              :key="item.id"
              @click="
                tapPopup(book.bookName + '_' + item.fileName, item.filePath)
              "
            >
              <img
                class="text"
                src="https://res.cdn.quyixian.com/front/text.png"
              />
              <div class="title">
                <p>{{ item.fileName }}</p>
                <img
                  class="down1"
                  src="https://res.cdn.quyixian.com/front/down1.png"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="nolist">{{ book.folderName }}</div>
            <div class="nolist">没找到其他资源</div>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="loadingExce">
        <div class="primary-nothing">
          <img class="cover" src="../assets/images/nothing.png" alt="" />
          <p class="title">您查找的内容不存在</p>
          <router-link
            :to="{
              name: 'propose',
              query: { bid: bid, fid: fid, source: source },
            }"
            class="btn"
          >
            <img class="icon" src="../assets/images/ope.png" alt="" />
            我要反馈
          </router-link>
        </div>
      </template>
      <template v-else>
        <div class="primary-folder-no">
          <van-loading>{{ loadingText }}</van-loading>
        </div>
      </template>
    </template>
    <van-popup v-model="downShow" style="width: 90%" round closeable>
      <div class="downpopup" v-if="loading">
        <div class="downpopup-title">
          {{ downName }}
        </div>
        <div class="downpopup-bottom">
          <a :href="downUrl" target="_bank" class="down">下载至手机</a>
        </div>
        <div class="downpopup-mail">
          <van-field v-model="mail" placeholder="输入邮箱" />
          <van-button class="down" @click="tapMail">下载至邮箱</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import qs from "qs";
import { Dialog, Toast } from "vant";

export default {
  name: "teachfolder",
  data() {
    return {
      loading: false,
      loadingText: "解析中...",
      listLoading: false,
      listLoadingText: "资源列表加载中...",
      loadingExce: false,
      bid: this.$route.query.bid,
      fid: this.$route.query.fid,

      //
      mail: "",
      downShow: false,
      downName: "",
      downUrl: "",
      book: "",
      resList: [],
      resListState: false,
      cover: this.$store.state.iCdn + "2021XXNewBook/images/nobook.jpg",
      source: 2,
    };
  },
  watch: {},
  mounted() {
    //this.bid = '437614389994983424';
    //this.fid = '451757849152851968';

    //this.bid = '443004666419089408';
    //this.fid = '443004761524932608';
    //console.log(this.resList[0].filePath)
    //判断参数是否有值
    if (
      this.bid != undefined &&
      this.bid != "" &&
      this.fid != undefined &&
      this.fid != ""
    ) {
      //this.getBook();
      this.getFolder();
    } else {
      this.loading = false;
      this.loadingText = "解析URL出错啦...";
      this.loadingExce = true;
    }
  },
  methods: {
    //全部资源下载记录
    allDown(e) {
      if (e != null || e != "") {
        let params = {
          bid: this.bid,
          fid: this.fid,
        };

        this.$api.mary.DownFolderPackge(params).then((res) => {
          let { tag, message, data } = res;
          if (tag == 1) {
            window.open(e, "_blank");
          } else {
            Dialog.alert({
              message: message,
            });
          }
        });
      }
    },

    //截取出版社
    publisher(e) {
      let s = this.book.publisher.replace(new RegExp("\\/", "g"), "</br>");
      return s;
    },

    //获取图书
    getBook() {
      this.loading = false;
      let params = {
        bid: this.bid,
      };
      this.$api.mary.GetBookInfo(params).then((res) => {
        let { tag, message, data } = res;
        if (tag == 1) {
          this.book = data;
          this.loading = true;
          if (this.book.coverImg != "") {
            this.cover = this.book.coverImg;
          }
          if (
            this.book.publisher != "" &&
            this.book.publisher.indexOf("/") > 0
          ) {
            this.book.publisher = this.book.publisher.replace(
              new RegExp("\\/", "g"),
              "</br>"
            );
          }
          sessionStorage.clear("bookinfo");
          sessionStorage.setItem("bookinfo", qs.stringify(data));
          //sessionStorage.setItem('book',Data);
          //let b = sessionStorage.getItem('book');
        } else {
          this.loadingExce = true;
          this.loading = false;
          this.loadingText = "图书走丢了...";
        }
        //console.log(res);
      });
    },

    tapPopup(name, url) {
      if (url) {
        this.downShow = !this.downShow;
        this.downName = name;
        this.downUrl = url;
      } else {
        Toast("无资源地址");
      }
    },
    //发送邮箱
    tapMail() {
      // 1.当下载单个资源的时候     可以传递 {bookName}_{fileName}
      // 2.下载整书资源的时候         可以传递{bookName}
      if (this.isValidEmail(this.mail)) {
        let params = {
          name: this.downName,
          AllResUrl: this.downUrl,
          Email: this.mail,
        };
        this.$api.mary.sendMail(params).then(({ tag }) => {
          if (tag == 1) {
            Toast("邮件发送成功");
            this.downShow = false;
          } else {
            Toast("邮件发送失败");
          }
        });
      } else {
        Toast("请输入正确的邮箱");
      }
    },
    isValidEmail(email) {
      // 使用正则表达式判断邮箱格式
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailRegex.test(email);
    },

    //获取目录
    getFolder() {
      this.listLoadingoading = false;
      let params = {
        bid: this.bid,
        fid: this.fid,
      };
      this.$api.mary.getTeachFolder(params).then(({ tag, data }) => {
        if (tag == 1) {
          this.book = data;
          if (data.resList && data.resList.length > 0) {
            this.resListState = true;
            this.resList = data.resList;
          }
          this.loading = true;
        } else {
          this.loadingExce = true;
          this.loading = false;
          this.loadingText = "列表资源走丢了...";
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.mxy-noDown {
  font-size: 0.45rem;
}
.downpopup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.3rem;

  &-title {
    text-align: center;
    margin-top: 0.5rem;
  }
  &-bottom {
    display: flex;
    justify-content: center;
    margin: 0.15rem 0;
    padding-bottom: 0.3rem;
    border-bottom: 1px dotted #999;
    a {
      width: 3.7rem;
      padding: 0.15rem 0.6rem;
    }
  }
  &-mail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.3rem 0;

    .van-field {
      border: 1px solid #999;
      width: 80%;
      border-radius: 0.15rem;
    }
    .down {
      width: 5rem;
      margin-top: 0.15rem;
    }
  }

  .down {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6abd44;
    color: #fff;
    border-radius: 0.15rem;
  }
}
.primary-explain {
  padding: 0 1em;
  font-size: 0.32rem;
  color: red;
  line-height: 1.6;
  margin-bottom: 0.15rem;
}
</style>
